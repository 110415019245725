<!--一带一路-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col >
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col >
        <el-row class="contentDiv">
          <div class="roadBackground flexContainer">
            <div v-for="(item, index) in areaList" :key="index"
                 :class="item.class" @click="changeActiveIndex(item, index)">
              <div class="imgContainer flexACenterJCenter">
                <img src="@/assets/img/giCulture/yellowIcon.png" alt="" v-show="activeIndex !== index"
                     class="yellowIcon">
                <img src="@/assets/img/giCulture/blueIcon.png" alt="" v-show="activeIndex === index"
                     class="blueIcon">
              </div>
              <div class="areaDot onlyFontClass" :class="activeIndex === index ? 'blurDiv' : 'yellowDiv'">{{item.name}}</div>
            </div>
          </div>
        </el-row>
        <div class="bottomRow">
          <div class="flexAlignItemCenter">
            <img :src="provinceImgUrl" alt="" class="giProvinceImgClass">
            <span class="giProvinceName">{{provinceName}}</span>
          </div>
          <div class="w-e-text-container">
            <div v-html="pContent" data-slate-editor></div>
          </div>
          <div class="imgsContainer rowSpaceAround">
            <div class="imgAndName" v-for="(item, index) in imgList" :key="index" @click="imgClick(item, index)">
              <div class="colFlexCenter flexACenterJCenter" style="height: 100%">
                <img :src="item.url" alt="" class="imgClass" v-show="item.url">
                <div :class="imgActiveIndex === index ? 'picName' : 'unPicName'">{{item.basicGiName}}</div>
              </div>
              <hr class="bottomLine" v-show="imgActiveIndex === index">
            </div>
          </div>
          <el-row>
            <el-col :span="8" class="bottomColClass">
              <img :src="giUrl" alt="" class="giImgClass" v-show="giUrl">
            </el-col>
            <el-col :span="16" class="bottomColClass">
              <div class="giNameClass">{{giName}}</div>
              <div class="w-e-text-container">
                <div v-html="content" data-slate-editor></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import { echoImg } from '@/utils/commonApi'

export default {
  name: 'theBeltAndRoadInitiative',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '国际交流',
        },
        {
          name: '一带一路',
          path: '/international/theBeltAndRoadInitiative'
        },
      ],
      basicArea: '',
      areaList: [
        { name: '西安', code: '1', class: 'giXian' },
        { name: '兰州', code: '2', class: 'giLanzhou' },
        { name: '乌鲁木齐', code: '3', class: 'giUrumqi' },
        { name: '哈萨克', code: '4', class: 'giKazakhstan' },
        { name: '吉尔吉斯', code: '5', class: 'giKyrgyzstan ' },
        { name: '乌兹别克斯坦', code: '6', class: 'giUzbekistan ' },
        { name: '塔吉克', code: '7', class: 'giTajikistan' },
        { name: '伊朗', code: '8', class: 'giIran' },
        { name: '土耳其', code: '9', class: 'giTürkiye' },
        { name: '俄罗斯', code: '10', class: 'giRussia' },
        { name: '德国', code: '11', class: 'giGermany' },
        { name: '荷兰', code: '12', class: 'giNetherlands' },
        { name: '意大利', code: '13', class: 'giItaly' },
      ],
      activeIndex: 0,
      imgActiveIndex: 0,
      imgList: [],
      provinceImgUrl: '', // 省地标图片地址
      provinceName: '', // 省地标协会名称
      giUrl: '', // 地标图片地址
      giName: '', // 地标名称
      content: '', // 地标内容
      pContent: '', // 省地标内容
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    changeActiveIndex(val, index) {
      this.activeIndex = index;
      this.basicArea = val.code;
      this.imgList = [];
      this.giUrl = '';
      this.giName = '';
      this.content = '';
      this.provinceImgUrl = '';
      this.provinceName = '';
      this.pContent = '';
      this.imgActiveIndex = 0;
      this.getData();
    },
    imgClick(val, index) {
      this.imgActiveIndex = index
      this.giUrl = val.url;
      this.giName = val.basicGiName;
      this.content = val.basicInformation;
    },
    getData() {
      let params = {
        basicArea: this.basicArea,
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/nitiativeBasin/basic",
        data: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          if (res.data.data.data.length > 0) {
            let data = res.data.data.data[0];
            this.provinceImgUrl = data.basicPic ? echoImg(data.basicPic) : '';
            this.provinceName = data.basicAssociation || '';
            this.pContent = data.basicInformation || '';

            if (data.basicList && data.basicList.length > 0) {
              data.basicList.forEach(item => {
                item.url = item.basicPic ? echoImg(item.basicPic) : '';
              })
              this.imgList = data.basicList;
              this.giUrl = this.imgList[0].url
              this.giName = this.imgList[0].basicGiName
              this.content = this.imgList[0].basicInformation
              this.$forceUpdate()
            }
          }
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.contentDiv {
  margin: 10px 0 0;
}
.roadBackground {
  height: 445px;
  width: 100%;
  background-image: url("~@/assets/img/giCulture/roadBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 10px 0 0;

  .areaDot {
    padding: 14px 7px 6px;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #fff;
    line-height: 20px;
    width: 17px;
    text-align: center;
  }
  .yellowDiv {
    background-image: url("~@/assets/img/giCulture/yellowRectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .blurDiv {
    background-image: url("~@/assets/img/giCulture/blueRectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgContainer {
    width: 32px;
    height: 32px;
  }

  .yellowIcon {
    width: 20px;
    height: 20px;
  }
  .blueIcon {
    width: 29px;
    height: 29px;
  }

  // 西安
  .giXian {
    position: absolute;
    left: 989px;
    top: 314px;

    .onlyFontClass {
      position: absolute;
      top: -10px;
      left: 35px;
    }
  }
  // 兰州
  .giLanzhou {
    position: absolute;
    left: 941px;
    top: 268px;

    .onlyFontClass {
      position: absolute;
      top: 36px;
      left: -5px;
    }
  }
  // 乌鲁木齐
  .giUrumqi {
    position: absolute;
    left: 820px;
    top: 192px;

    .onlyFontClass {
      position: absolute;
      top: -104px;
      left: -2px;
    }
  }
  // 哈萨克
  .giKazakhstan {
    position: absolute;
    left: 712px;
    top: 187px;
    .onlyFontClass {
      position: absolute;
      top: -85px;
      left: -4px;
    }
  }
  // 吉尔吉斯
  .giKyrgyzstan {
    position: absolute;
    left: 660px;
    top: 224px;

    .onlyFontClass {
      position: absolute;
      top: 35px;
      left: 0;
    }
  }
  // 乌兹别克斯坦
  .giUzbekistan {
    position: absolute;
    left: 582px;
    top: 205px;

    .onlyFontClass {
      position: absolute;
      top: 35px;
      left: 0;
    }
  }
  // 塔吉克
  .giTajikistan {
    position: absolute;
    left: 625px;
    top: 231px;

    .onlyFontClass {
      position: absolute;
      top: -83px;
      left: 0;
    }
  }
  // 伊朗
  .giIran {
    position: absolute;
    left: 437px;
    top: 164px;

    .onlyFontClass {
      position: absolute;
      top: 32px;
      left: 0;
    }
  }
  // 土耳其
  .giTürkiye {
    position: absolute;
    left: 328px;
    top: 229px;

    .onlyFontClass {
      position: absolute;
      top: 30px;
      left: 0;
    }
  }
  // 俄罗斯
  .giRussia {
    position: absolute;
    left: 493px;
    top: 125px;

    .onlyFontClass {
      position: absolute;
      top: -80px;
      left: 0;
    }
  }
  // 德国
  .giGermany {
    position: absolute;
    left: 278px;
    top: 229px;

    .onlyFontClass {
      position: absolute;
      top: -60px;
      left: 0;
    }
  }
  // 荷兰
  .giNetherlands {
    position: absolute;
    left: 260px;
    top: 275px;

    .onlyFontClass {
      position: absolute;
      top: 30px;
      left: 0;
    }
  }
  // 意大利
  .giItaly {
    position: absolute;
    left: 154px;
    top: 205px;

    .onlyFontClass {
      position: absolute;
      top: -82px;
      left: 0px;
    }
  }
}

.imgsContainer {
  width: 100%;
  height: 140px;
  margin: 0 0 10px;
  border-bottom: solid 2px #E7ECF0;

  .imgAndName {
    height: 100%;

  }
  .imgClass {
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }
  .picName {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #186CF5;
    line-height: 24px;
    margin: 10px 0 0;
  }
  .unPicName {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin: 10px 0 0;
  }
  .bottomLine {
    width: 100%;
    height: 2px;
    background: #186CF5;
    position: relative;
    top: -8px;
  }
}

.triangle {
  width: 30px;
  height: 30px;
  background: white;
  transform: rotate(45deg);
  position: relative;
  top: -7px;
  box-shadow: 0px 2px 20px 0px rgba(34,80,158,0.06);
}
.bottomRow {
  margin-bottom: 25px;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(34,80,158,0.06);

  .bottomColClass {
    padding: 20px;
  }

  .giImgClass {
    width: 100%;
    height: 100%;
    background: #D8D8D8;
    border-radius: 7px;
  }
  .giNameClass {
    font-size: 28px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
  }
  .giProvinceImgClass {
    width: 50px;
  }
  .giProvinceName {
    font-size: 28px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    margin-left: 10px;
  }
}
</style>
